import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Header from './Header';
import { UserContext } from '../context/UserContext';
import Pagination from './Pagination'
import Divers from '../assets/images/Divers/Divers 1-1.png'

export default function MatinBrun0page() {
  const { blur } = useContext(UserContext);
  return (

    
    <>
      <Header color="black"/>


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={blur ? "blur-sm " : ""}

        sx={{ minHeight: '100vh', backgroundColor: 'black' }}
      >
        
  <img className="-mt-36" src={Divers} alt="Logo" />

        <Grid className="mt-[30rem] fixed -ml-[90rem]">
        <Pagination
           currentpage={1}
           postsPerPage={2}
           totalPosts={6}
           nextPage={'/Divers2'}
           previouspage={'/Divers3'}
           theme={'empty'}
           blackChevron={'black'}
        />
        </Grid>
       
      </Grid>
    </>
  
  
  )
}

MatinBrun0page.propTypes = {
  children: PropTypes.element.isRequired,
};