import React, { Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { UserContext } from './context/UserContext';

import Scene1 from './MainLayout/Scene1';
import Dusky0Page from './MainLayout/Dusky0Page';
import Dusky1Page from './MainLayout/Dusky1Page';

import DuskyBlur from './MainLayout/DuskyBlur';
import DuskyScene from './MainLayout/DuskyScene';

import KinderAdvertising from './MainLayout/KinderAdvertising';
import MatinBrun0page from './MainLayout/MatinBrun0page';

import MatinBrun1page from './MainLayout/MatinBrun1page';
import SawPage from './MainLayout/SawPage';

import Sidebar from './MainLayout/Sidebar';
import SubliminalAdvertising from './MainLayout/SubliminalAdvertising';

import HogoCroquisPage from './MainLayout/HogoCroquisPage';
import HogoDetailsPage from './MainLayout/HogoDetailsPage';

import DiversPage0 from './MainLayout/DiversPage0';
import DiversPage1 from './MainLayout/DiversPage1';
import DiversPage2 from './MainLayout/DiversPage2';
import CollabFictivHome from './MainLayout/CollabFictivHome';
import CollabFictivInspi1 from './MainLayout/CollabFictivInspi1';
import CollabFictivExpe1 from './MainLayout/CollabFictivExpe1';
import CollabFictivInspi2 from './MainLayout/CollabFictivInspi2';
import CollabFictivExpe2 from './MainLayout/CollabFictivExpe2';
import CollabFictivInspi3 from './MainLayout/CollabFictivInspi3';
import CollabFictivExpe3 from './MainLayout/CollabFictivExpe3';
import CollabFictivFinal from './MainLayout/CollabFictivFinal';
import Preview from './MainLayout/Preview';





function App() {
const [blur, setBlur] = useState();


const Hogo = React.lazy( () => import('./MainLayout/Hogo0Page'));

  return (
<UserContext.Provider value={{ blur, setBlur }}>

    <BrowserRouter basename={process.env.PUBLIC_URL}>
<Suspense fallback={<>Yess</>}>
      <Routes>
      <Route path={"/"} element={<Scene1 />} />

        <Route path={"/Hogo"} element={<Hogo />} />
        <Route path={"/Dusky0Page"} element={<Dusky0Page />} />
        <Route path={"/Dusky1Page"} element={<Dusky1Page />} />
        <Route path={"/DuskyBlur"} element={<DuskyBlur />} />
        <Route path={"/DuskyScene"} element={<DuskyScene />} />
        <Route path={"/KinderAdvertising"} element={<KinderAdvertising />} />
        <Route path={"/MatinBrun0page"} element={<MatinBrun0page />} />
        <Route path={"/MatinBrun1page"} element={<MatinBrun1page />} />
        <Route path={"/SawPage"} element={<SawPage />} />
        <Route path={"/Sidebar"} element={<Sidebar />} />
        <Route path={"/SubliminalAdvertising"} element={<SubliminalAdvertising />} />
        <Route path={"/HogoCroquisPage"} element={<HogoCroquisPage />} />
        <Route path={"/HogoDetailsPage"} element={<HogoDetailsPage />} />
        <Route path={"/Divers1"} element={<DiversPage0 />} />
        <Route path={"/Divers2"} element={<DiversPage1 />} />
        <Route path={"/Divers3"} element={<DiversPage2 />} />

    
        <Route path={"/CollabFictivHome"} element={<CollabFictivHome />} />

        <Route path={"/CollabFictivInspi1"} element={<CollabFictivInspi1 />} />
        <Route path={"/CollabFictivInspi2"} element={<CollabFictivInspi2 />} />
        <Route path={"/CollabFictivInspi3"} element={<CollabFictivInspi3 />} />


        <Route path={"/CollabFictivExpe1"} element={<CollabFictivExpe1 />} />
        <Route path={"/CollabFictivExpe2"} element={<CollabFictivExpe2 />} />
        <Route path={"/CollabFictivExpe3"} element={<CollabFictivExpe3 />} />
       
        <Route path={"/CollabFictivFinal"} element={<CollabFictivFinal />} />

      </Routes>
      </Suspense>
    </BrowserRouter>
</UserContext.Provider>
    

  );
}

export default App;