import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Header from './Header';
import { UserContext } from '../context/UserContext';

import Hogo1 from '../assets/images/Croquis/HOGO expo 1.png'
import Hogo2 from '../assets/images/Croquis/HOGO expo 2.png'
import Hogo3 from '../assets/images/Croquis/Hogo logo 1.png'
import Hogo4 from '../assets/images/Croquis/Hogo logo 2.png'
import Hogo5 from '../assets/images/Croquis/Hogo logo 3.png'
import Hogo7 from '../assets/images/Croquis/Hogo logo 5.png'
import Hogo8 from '../assets/images/Croquis/Hogo logo 4.png'
import dos from '../assets/images/Croquis/dos écriture hogo-1.png'
import manche from '../assets/images/Croquis/manche version final-1.png'
import torse from '../assets/images/Croquis/torse final -1.png'
import Pagination from './Pagination'

export default function MatinBrun0page() {
  const { blur } = useContext(UserContext);
  return (

    <>
      <Header />


      <Grid

        className={blur ? "blur-sm w-[108%]" : "w-[108%]"}

        sx={{ minHeight: '102vh', backgroundColor: 'black' }}
      >
        <Grid container spacing={1}>



          <Grid className="w-80 ml-8 mt-64">
            <p className=" w-80 mb-4 text-white font-gallient-r text-7xl/[2.5rem] tracking-widest text-left ">
              Croquis
            </p>
            <span className="text-[0.95rem] mt-4 contents mt-32 text-white w-80 font-gallient-r text-l tracking-widest text-right ">
              Quelques croquis ont été produits en amont afin de
              définir l’identité visuelle de la marque et mettre en avant
              une direction artistique représentant l’univers japonais et
              sécurisé de la marque.
            </span>
          </Grid>


        </Grid>

        <Grid className="ml-[20rem] -mt-[19rem]">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >

            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={Hogo1} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={Hogo2} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={torse} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={dos} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={manche} alt="Logo" />
            </Grid>
          </Grid>


          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >

            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={Hogo3} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={Hogo4} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={Hogo5} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={Hogo8} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[15.285714%]">
              <img className="w-80" src={Hogo7} alt="Logo" />
            </Grid>
          </Grid>

        </Grid>

        <Grid className="-mt-36">
          <Pagination
            currentpage={3}
            postsPerPage={3}
            totalPosts={9}
            nextPage={'/Hogo'}
            previouspage={'/HogoDetailsPage'}
            theme={'empty'}
          />
        </Grid>
      </Grid>


    </>
  )
}

MatinBrun0page.propTypes = {
  children: PropTypes.element.isRequired,
};