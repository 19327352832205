import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Header from './Header';
import { UserContext } from '../context/UserContext';

import burberry from '../assets/images/Pub burberry optimisé.jpg'
import burberry2 from '../assets/images/Pub Burberry.png'
import Pagination from './Pagination'

export default function SubliminalAdvertising() {
  const { blur } = useContext(UserContext);



  return (
    
    <div sx={{
      position: 'fixed',
      backgroundColor: 'black'
    }}
    >
<Header/>

      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={blur ? "blur-sm " : "" } 

        sx={{ minHeight: '100vh', backgroundColor: 'black' }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>

            <Grid
              container
              className="!-mt-[10rem] !-ml-[70rem] !w-96"
            >

              <p
                className="mt-64 mb-8 text-white font-gallient-r text-7xl/[2.5rem] tracking-widest"
              >
                Axe 2
              </p>
              <p className="text-[0.70rem] contents mt-8 text-white font-gallient-r text-xl tracking-widest text-center ">
                Cet axe souligne la polémique de la maison de luxe Burberry,
                ayant brûlé pour plus de 30 millions d’euros d’invendus.
                Ce qui a un impact environnemental important, sans avoir à
                chercher d’autres solutions plus efficaces et écologiques.
              </p>
              <span className="text-[0.70rem] contents mt-32 text-white font-gallient-r text-xl tracking-widest text-center ">
                <br />Une poubelle avec les motifs de la marque est brûlée avec
                de la fumée qui s’échappe de la poubelle, avec le nom de
                la marque modifié par « Barbarry », montrant le mauvais
                comportement de la marque.
              </span>
            </Grid>
            <Grid className="!-mt-[15rem]">

              <Grid item className="!-mt-[14rem] w-[23rem] !ml-[27rem]" xs={3}>

                <img src={burberry2} alt="Logo" />
              </Grid>

              <Grid item className="!ml-[53rem] !-mt-[32.5rem]" xs={3}>

                <img src={burberry} alt="Logo" />

              </Grid>

            </Grid>



            <Grid className="-mt-[9rem] ml-[79rem]">
        <p className="text-[0.70rem] w-80 !ml-[77rem] !-mt-[26rem] contents text-white font-gallient-r text-xl tracking-widest text-center "
        >
          Cette création est axée sur
          l’environnement avec une nature brûlante
          et les motifs de la marque s’envolant
          dans les airs.
        </p>

      </Grid>



          </Grid>



        </Grid>

        <Grid className="-mt-32 -ml-[92rem]">
          <Pagination
            currentpage={1}
            postsPerPage={2}
            totalPosts={4}
            nextPage={'/KinderAdvertising'}
            previouspage={'/KinderAdvertising'}
            theme={'empty'}
          />
        </Grid>


      </Grid>

    
    </div>
  )
}

SubliminalAdvertising.propTypes = {
  children: PropTypes.element.isRequired,
};