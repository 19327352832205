import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Mockupfleur from '../assets/images/Mockupfleur.png'
import MockupCafe from '../assets/images/MockupCafe.png'
import Header from './Header';
import Pagination from './Pagination'

export default function MatinBrun1page() {
  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', position: 'fixed', backgroundColor: '#272035' }}
      >
        <Grid container className="!mt-4" justifyContent="center" spacing={1}>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid className="mt-20 w-[30rem] -ml-[28rem]">
              <Grid item className="w-full">
                <p
                  className="mt-64 mb-8 text-white font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Axe 3
                </p>
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white font-gallient-r  tracking-widest text-center ">
                  Pour finir, mon troisième choix graphique, représente
                  une fleur fanée qui perd petit à petit sa fraîcheur.
                </p>
              </Grid>
              <Grid item className="w-full">
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white  font-gallient-r  tracking-widest text-center ">
                  Cela peut définir plusieurs points de vues, celui de
                  l’évolution de la situation (la fleur qui retrouve sa
                  couleur) ou bien l’inverse, la dégradation de la situation
                  suite à l’annonce de la dictature (la fleur qui perd sa
                  beauté).
                </p>
              </Grid>
            </Grid>
            <Grid className="ml-[-6rem]">
              <Grid container justifyContent="center">
                <Grid item>
                  <img className="w-[38rem] h-[21rem]" src={MockupCafe} alt="Logo" />

                </Grid>

              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <img className="w-[38rem] h-[21rem]" src={Mockupfleur} alt="Logo" />

                </Grid>
              </Grid>
            </Grid>

            <Grid className="-mt-[52rem] ml-[62rem] -mt-[46rem]">
              <Grid item>
                <p
                  className="mt-64 mb-8 text-white font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Axe 2
                </p>
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white font-gallient-r  tracking-widest text-center ">
                  Dans mon deuxième choix graphique, j’accentue la
                  propagande de la nouvelle qui diffuse son message
                  contre les animaux bruns tous les matins (dans les
                  journaux, radio...).
                </p>
              </Grid>
              <Grid item className="mt-24">
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white font-gallient-r  tracking-widest text-center ">
                  La création à un aspect vintage représentant le journal
                  avec une tasse de café possédant un latte d’une croix
                  gammée stylisée, afin de montrer la dictature.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="-mt-44 -ml-[92rem]">
          <Pagination
             currentpage={1}
             postsPerPage={2}
             totalPosts={4}
            nextPage={'/MatinBrun0page'}
            previouspage={'/MatinBrun0page'}
            theme={'empty'}

          />
        </Grid>
      </Grid>
    </>
  )
}

MatinBrun1page.propTypes = {
  children: PropTypes.element.isRequired,
};