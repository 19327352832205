import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import MockupCafe from '../assets/images/Axe final.png'
import Header from './Header';
import Pagination from './Pagination'

export default function MatinBrun1page() {
  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', position: 'fixed', backgroundColor: 'black' }}
      >
        <Grid container className="!mt-20" justifyContent="center" spacing={1}>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid className="mt-[19rem] w-[20rem] -ml-[70rem]">


              <Grid item className="w-38">
                <p
                  className="w-[35rem] -top-[21rem] relative ml-12 mb-8 text-white font-gallient-r text-[2.5rem] tracking-widest"
                >
                 Axe final

                </p>
                <img className="ml-[-3rem] w-[91rem] top-[-35rem] relative !max-w-[102rem]" src={MockupCafe} alt="Logo" />

              </Grid>
            </Grid>



          </Grid>
        </Grid>
        <Grid className="-mt-[51rem] -ml-[88rem]">
        <Pagination
            currentpage={4}
            postsPerPage={6}
            totalPosts={19}
            nextPage={'/CollabFictivHome'}
            previouspage={'/CollabFictivInspi3'}
            theme={'empty'}

          />
        </Grid>
      </Grid >
    </>
  )
}

MatinBrun1page.propTypes = {
  children: PropTypes.element.isRequired,
};