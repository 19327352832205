import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Header from './Header';

import ModeStandbyIcon from '@mui/icons-material/ModeStandby';


import animfirstpage from '../assets/images/Animation site 0040-0130.mkv'

export default function Sidebar() {
  const [blur, setBlur] = useState(false);

  const handleClose = () => {
    setBlur(!blur);
    console.log(blur)
  };

  return (

    <>
    <Header/>
      {blur &&
        <ul className="text-white z-10 mt-16 absolute leading-[4rem] blur-none cursor-pointer hover:cursor-pointer block w-[40rem] text-left font-gallient-r text-5xl tracking-widest	">
          <li>  <Link to="/Hogo">Hogo</Link> </li>
          <li><Link to="/MatinBrun0page">Matin Brun</Link></li>
          <li><Link to="/SawPage">Saw poster</Link></li>
          <li><Link to="/CollabFictivHome">Collaboration fictive</Link></li>
          <li><Link to="/KinderAdvertising">Détournement publicitaires</Link></li>
          <li><Link to="/Dusky0Page">Dusky</Link></li>
          <li><Link to="/Saw">Divers</Link></li>
        </ul>
      }
      <div className={blur ? "w-full blur-sm" : "w-full"}>
        <video autoPlay loop muted className="bg-cover">
          <source src={animfirstpage} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed background-color: rgba(0, 0, 0, 0.6)"
        >
          <div className="flex h-full items-center justify-center">
            <div className="text-white">



              {/* Vêtement hogo */}
              <IconButton aria-label="delete" className=" animate-ping-slow top-16 size-8 right-40 white z-20"
                onClick={() => { handleClose() }}>
                <ModeStandbyIcon style={{ color: 'white' }} />
              </IconButton>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Sidebar.propTypes = {
  children: PropTypes.element.isRequired,
};