import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import Expe from '../assets/images/Expé Axe 1.png'
import Inspi from '../assets/images/Inspi Axe 1.png'
import Header from './Header';
import Pagination from './Pagination'
import { Preview } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import CollabFictivExpe1 from './CollabFictivExpe1';

export default function MatinBrun1page() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', position: 'fixed', backgroundColor: 'black' }}
      >
        <Grid container className="!-mt-4" justifyContent="center" spacing={1}>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid className="-mt-[11rem] w-[26rem] -ml-[64rem]">
              <Grid item className="w-full">
                <p
                  className="mt-64 mb-4 text-white leading-[3.5rem] font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Axe 1 :
                  Héritage
                </p>
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white font-gallient-r  tracking-widest text-center ">
                  Dans cette axe, le but étant de fusionner harmonieusement les
                  influences de l’Afrique et des Antilles dans notre esthétique, en
                  mettant en avant des motifs traditionnels et des couleurs vibrantes.
                  <br /><br />
                  L’objectif est de créer une fusion unique qui représente la
                  richesse culturelle et l’héritage de ces régions, en utilisant des
                  motifs tribaux, des imprimés wax, s’inspirer des pagnes et des
                  motifs emblématiques des Antilles tel que le madras.
                  En combinant l’identité des deux marques, nous utilisons une
                  palette de couleurs qui inclut le jaune, le noir, le blanc, le rouge,
                  l’orange et le marron.

                  <br />
                  Ces couleurs sont soigneusement choisies pour symboliser la
                  vitalité et la diversité de nos influences culturelles.
                </p>

                <Button className="!bg-[#1c1c1c] !text-white !block !font-gallient-r font-bold py-2 px-4 rounded w-64	h-12"
                  aria-owns={open ? 'mouse-over-popover' : 'mouse-over-popover'}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  onClick={() => {
                    navigate('/CollabFictivexpe1');
                  }}
                >
                  Planche d’expérimentation      </Button>
                <Popover
                  className='w-24 max-h-28-mt-[45rem] ml-16'
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={true}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >

                  <CollabFictivExpe1 />
                </Popover>
              </Grid>

              <Grid item className="w-38">
                <img className="ml-[10rem] w-[91rem] top-[-44rem] relative !max-w-[102rem]" src={Inspi} alt="Logo" />

              </Grid>
            </Grid>



          </Grid>
        </Grid>
        <Grid className="-mt-[60rem] -ml-[88rem]">
          <Pagination
            currentpage={2}
           postsPerPage={6}
           totalPosts={19}
           nextPage={'/CollabFictivInspi2'}
           previouspage={'/CollabFictivHome'}
            theme={'empty'}

          />
        </Grid>
      </Grid >
    </>
  )
}

MatinBrun1page.propTypes = {
  children: PropTypes.element.isRequired,
};