import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Header from './Header';

import flou from '../assets/images/Flou site 2.jpg'

export default function DuskyBlur() {
 
<Header/>

  return (
    
    <div className="-mt-36"
      
    >
      <img className="bg-cover w-full" src={flou} alt="Logo" />

      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="-mt-[46rem] w-[26rem] ml-80"
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>

            <Grid container spacing={2} columns={8}>
              <Grid item xs={8} />

              <Grid item xs={2} />

              <Grid className="-mt-[11rem] w-[25rem] -ml-[34rem]">
                <p
                  className="mt-64 mb-8 text-center text-[#c85927] font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  DUSKY
                </p>
                <span
                 className="text-left	 ml-64 contents mt-32 text-white font-gallient-r text-[0.70rem] tracking-widest"
                >
                  « Dusky procède à une vengeance auprès de son histoire, totalement
                  pacifique car cette dernière est retranscrite dans l’art de la mode. »<br /><br />
                  Dusky est une maison qui rend hommage à l’histoire des noirs et toute les
                  personnalités qui se sont battus passivement ou non pour la liberté et l’égalité
                  de cette cause.<br /><br />
                  Le nom « Dusky » signifie obscurité en anglais et fait référence aux actes
                  inf ligés par certains partis tel que la « Black Panther Party », qui procédait
                  à une sorte de révolution sanglante afin de se faire entendre, mais ce nom
                  signifie également la couleur de peau noir pour que chaque personnes soit
                  fier de son ethnie et de son histoire.
                </span>
              </Grid>

              <Grid item xs={2} />

            </Grid>

          </Grid>

        </Grid>
      </Grid>
    </div>
  )
}

DuskyBlur.propTypes = {
  children: PropTypes.element.isRequired,
};