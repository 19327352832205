import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import MockupCafe from '../assets/images/Logo collab.png'
import Header from './Header';
import Pagination from './Pagination'
import Charte from './Charte.pdf';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { useTheme } from '@mui/system';
import { UserContext } from '../context/UserContext';

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
}


export default function MatinBrun1page() {

  const isDarkMode = useIsDarkMode();
  const [anchor, setAnchor] = React.useState(null);

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popup' : undefined;
const { blur } = useContext(UserContext);

  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        className={blur ? "blur-sm bg-white " : "bg-white" } 
        justifyContent="center"
        sx={{ minHeight: '100vh', position: 'fixed', backgroundColor: 'black' }}
      >
        <Grid container className="!-mt-12" justifyContent="center" spacing={1}>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid className="-mt-[9rem] w-[30rem] -ml-[61rem]">
              <Grid item className="w-full">
                <p
                  className="mt-64 mb-2 text-white leading-[3.5rem] font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Collaboration
                  fictive
                </p>
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white font-gallient-r  tracking-widest text-center ">
                  Mon projet vise à rendre hommage, à travers une marque, aux
                  activistes noirs qui se sont battus, pacifiquement ou non, pour
                  leur liberté, leurs droits et l’égalité.
                  <br /><br />
                  Mon objectif est de créer l’identité visuelle d’une collaboration avec
                  une marque partageant les mêmes valeurs que les miennes, en
                  communiquant et en travaillant en grande partie avec des personnes
                  noires

                  <br /><br />
                  La collaboration se concentrera sur le prêt-à-porter de luxe en
                  s’engageant à reverser une partie des ventes vers des associations,
                  afin de financer des entreprises émergentes.
                  <br />
                  De plus, des événements ouverts au public seront organisés dans
                  le but de présenter cette collaboration et de mettre en avant de
                  jeunes créateurs, valorisant ainsi leur travail et leur visibilité.
                </p>

     <Button className="z-[1] relative top-8 !flex !bg-[#1c1c1c] !text-white !font-gallient-r font-bold py-2 px-4 !rounded w-64	h-12"
                  aria-describedby={id}
                  type="button"
                  onClick={handleClick}
                >
        Charte graphique
      </Button>
      <BasePopup
        id={id}
        open={open}
        anchor={anchor}
        disablePortal
        className="z-[3]"
      >
        <iframe className="z-[3] w-[90rem] h-[35rem] relative " src={Charte}
          frameborder="0"/>
      </BasePopup>


              

              </Grid>

              <Grid item className="w-38">
                <img className="z-0 relative ml-[10.5rem] w-[70rem] top-[-38rem] relative !max-w-[102rem]" src={MockupCafe} alt="Logo" />

              </Grid>
            </Grid>


            <Grid className="-mt-[86rem]  ml-[66rem]">
              <Grid item>
                <p
                  className="mt-44 mb-4 text-white font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Contexte
                </p>
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white font-gallient-r  tracking-widest text-center ">
                  Le black washing est une pratique qui consiste à mettre en avant la diversité ethnique au sein d’un organisme à des fins publicitaires.
                   Ce phénomène s’apparente à une stratégie marketing similaire au green washing, où les entreprises polluantes cherchent à améliorer leur image en mettant en avant des actions écologiques.

                  <br /><br />
                  Cette pratique est souvent critiquée car elle exploite la diversité ethnique à des fins lucratives, sans véritable engagement envers l’équité ou la justice sociale. Elle peut également contribuer à perpétuer des stéréotypes raciaux en réduisant les personnes noires à de simples outils de marketing, plutôt qu’en reconnaissant leur humanité et leur dignité.

                  <br /><br />
                  Le concept de Black-owned est directement issu d’un mouvement principalement afro-américain visant à encourager l’achat et l’investissement auprès des Noirs. Il ne s’agit pas simplement d’une tendance passagère, mais plutôt d’un mode de vie, car opter pour cette démarche constitue une forme de militantisme pacifique contre les inégalités raciales.

                </p>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        <Grid className="relative -top-[47rem] -ml-[88rem]">
        <Pagination
           currentpage={1}
           postsPerPage={6}
           totalPosts={19}
           nextPage={'/CollabFictivInspi2'}
           previouspage={'/CollabFictivInspi3'}
            theme={'empty'}

          />
        </Grid>
      </Grid >
    </>
  )
}

MatinBrun1page.propTypes = {
  children: PropTypes.element.isRequired,
};