import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import Inspi from '../assets/images/Inspi Axe 2.png'
import Header from './Header';
import Pagination from './Pagination'
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import CollabFictivExpe2 from './CollabFictivExpe2';

export default function MatinBrun1page() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', position: 'fixed', backgroundColor: 'black' }}
      >
        <Grid container className="!-mt-8" justifyContent="center" spacing={1}>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid className="-mt-[11rem] w-[37rem] -ml-[54rem]">
              <Grid item className="w-full">
                <p
                  className="mt-64 mb-4 text-white leading-[3.5rem] font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Axe 2 :
                  Inclusivité
                </p>
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white font-gallient-r  tracking-widest text-center ">
                  Dans cet axe graphique, j’ai voulu explorer un univers inclusif en
                  jouant avec la typographie et en variant l’épaisseur ainsi que les
                  formes des lettres.
                  <br />
                  Cette variation inclut également des caractères de l’alphabet
                  bambara et des symboles adinkra, enrichissant ainsi notre design
                  d’éléments culturels authentiques.
                  <br /><br />
                  Ce choix stylistique crée un logo qui reflète la diversité et
                  l’inclusivité des communautés noires, en célébrant différentes
                  traditions et identités visuelles.
                  <br />
                  En intégrant ces éléments, nous visons à représenter toutes les
                  morphologies et à honorer la richesse culturelle de chaque
                  groupe.
                  <br /><br />

                  Cette approche permet non seulement de créer un visuel
                  distinctif et significatif, mais aussi de renforcer notre engagement
                  envers la diversité et l’inclusion. En combinant des influences
                  typographiques variées avec des symboles culturels, nous
                  aspirons à concevoir une identité graphique qui est à la fois
                  unique et représentative de l’ensemble des communautés noires.
                </p>

                <Button className="!bg-[#1c1c1c] !text-white !font-gallient-r font-bold py-2 px-4 rounded w-64	h-16"
                  aria-owns={open ? 'mouse-over-popover' : 'mouse-over-popover'}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  onClick={() => {
                    navigate('/CollabFictivexpe2');
                  }}
                >
                  Planche d’expérimentation      </Button>
                <Popover
                  className='w-24 max-h-28-mt-[45rem] ml-16'
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={true}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >

                  <CollabFictivExpe2 />
                </Popover>

              </Grid>

              <Grid item className="w-38">
                <img className="ml-[18rem] w-[91rem] top-[-41rem] relative !max-w-[102rem]" src={Inspi} alt="Logo" />

              </Grid>
            </Grid>



          </Grid>
        </Grid>
        <Grid className="relative -top-[60rem] -ml-[87rem]">
          <Pagination
            currentpage={2}
            postsPerPage={6}
            totalPosts={19}
            nextPage={'/CollabFictivInspi3'}
            previouspage={'/CollabFictivInspi1'}
            theme={'empty'}

          />
        </Grid>
      </Grid >
    </>
  )
}

MatinBrun1page.propTypes = {
  children: PropTypes.element.isRequired,
};