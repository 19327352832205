import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Header from './Header';
import { UserContext } from '../context/UserContext';

import dusky from '../assets/images/DUSKY logo (fond marron).jpg'
import duskyy2 from '../assets/images/DUSKY logo chérubin (fond marron).jpg'
import Pagination from './Pagination'

export default function Dusky1Page() {
  const { blur } = useContext(UserContext);
  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={blur ? "blur-sm " : ""}

        sx={{ minHeight: '102vh', backgroundColor: '#ca5a27' }}
      >
        <Grid className="mt-16">
          <Grid container justifyContent="center" spacing={1}>

            <Grid container className="mt-8 ml-8 w-80 text-center">



              <Grid className="">
                <p className=" block w-80 text-left font-gallient-r tracking-widest text-7xl/[2.5rem] text-left "
                >
                  Dusky
                </p>
                <span className="text-[0.95rem] mt-4 block w-80 text-left font-gallient-r tracking-widest mt-8">
                  Le logo illustre un chérubin qui est une créature
                  spirituelle décrite comme un messager adorateur de
                  Dieu, on le voit en l’occurrence en train de subir mais
                  reste tout de même debout. <br /> <br />
                  Le message de Dusky est de représenter à travers cet
                  ange tous les défunts militants se battant pour l’égalité
                  des Noirs.<br /> <br />
                  Le orange est utilisé afin d’apporter de la chaleur et du
                  dynamisme et enfin le marron (qui est souvent associé
                  au cuir et au bois) sert à apporter un côté élégant et
                  solide à la marque.


                </span>


              </Grid>
              <Grid className="flex -mt-[36rem] ml-[25rem]">
                <Grid className="mt-28 mr-2 flex">

                  <img className="h-[34rem] w-[34rem]" src={duskyy2} alt="Logo" />
                </Grid>

                <Grid className="mt-28 mr-2 flex">

                  <img className="h-[34rem] w-[34rem]" src={dusky} alt="Logo" />
                </Grid>
              </Grid>


            </Grid>

          </Grid>

        </Grid>
        <Grid />



        <Grid className="-mt-40 -ml-[92rem]">
          <Pagination
            currentpage={2}
            postsPerPage={2}
            totalPosts={4}
            nextPage={'/Dusky0Page'}
            previouspage={'/Dusky0Page'}
            theme={'empty'}
          />
        </Grid>
      </Grid>

    </>

  )
}

Dusky1Page.propTypes = {
  children: PropTypes.element.isRequired,
};