import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import Inspi from '../assets/images/Inspi Axe 3.png'
import Header from './Header';
import Pagination from './Pagination'
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import CollabFictivExpe3 from './CollabFictivExpe3';

export default function MatinBrun1page() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', position: 'fixed', backgroundColor: 'black' }}
      >
        <Grid container className="!-mt-4" justifyContent="center" spacing={1}>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid className="-mt-[9rem] w-[30rem] -ml-[62rem]">
              <Grid item className="w-full">
                <p
                  className="mt-64 mb-4 text-white leading-[3.5rem] font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Axe 3 :
                  Appartenance
                </p>
                <p className="text-[0.95rem] ml-64 contents mt-32 text-white font-gallient-r  tracking-widest text-center ">
                  Dans cette approche graphique, j’ai exploré les motifs en intégrant des
                  objets et des éléments symboliques utilisés par les Afro-descendants.
                  J’ai notamment inclus des fruits exotiques et des fleurs, qui
                  représentent non seulement la richesse de la nature, mais également
                  des aspects culturels spécifiques, comme l’emblématique «île aux
                  fleurs», la Martinique
                  <br /><br />
                  Ces choix graphiques visent à capturer l’essence et l’identité de ces
                  cultures à travers des éléments visuels emblématiques. En intégrant
                  ces motifs, nous souhaitons non seulement créer une esthétique
                  visuellement riche et attrayante, mais aussi honorer les traditions et les
                  héritages des Afro-descendants.
                </p>

                <Button className="!bg-[#1c1c1c] !text-white !font-gallient-r font-bold py-2 px-4 rounded w-64	h-16"
                  aria-owns={open ? 'mouse-over-popover' : 'mouse-over-popover'}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  onClick={() => {
                    navigate('/CollabFictivexpe3');
                  }}
                >
                  Planche d’expérimentation      </Button>
                <Popover
                  className='w-24 max-h-28-mt-[45rem] ml-16'
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={true}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >

                  <CollabFictivExpe3 />
                </Popover>

              </Grid>

              <Grid item className="w-38">
                <img className="ml-[10rem] w-[91rem] top-[-42rem] relative !max-w-[102rem]" src={Inspi} alt="Logo" />

              </Grid>
            </Grid>



          </Grid>
        </Grid>
        <Grid className="relative -top-[57rem] -ml-[88rem]">
          <Pagination
            currentpage={3}
            postsPerPage={6}
            totalPosts={19}
            nextPage={'/CollabFictivFinal'}
            previouspage={'/CollabFictivInspi2'}
            theme={'empty'}

          />
        </Grid>
      </Grid >
    </>
  )
}

MatinBrun1page.propTypes = {
  children: PropTypes.element.isRequired,
};