import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import MockupCafe from '../assets/images/Expé Axe 2.png'
import Header from './Header';
import Pagination from './Pagination'
import { useNavigate } from 'react-router-dom';

export default function MatinBrun1page() {

  const navigate = useNavigate();

  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', position: 'fixed', backgroundColor: 'black' }}
      >
        <Grid container className="!mt-20" justifyContent="center" spacing={1}>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid className="mt-[19rem] w-[20rem] -ml-[70rem]">


              <Grid item className="w-38">
                <p
                  className="w-[21rem] -top-[21rem] relative ml-12 mb-8 text-white font-gallient-r text-[2.5rem] tracking-widest"
                >
                  Axe 2 : Inclusivité
                  Expérimentation
                </p>
                <img className="ml-[-1rem] w-[91rem] top-[-38rem] relative !max-w-[102rem]" src={MockupCafe} alt="Logo" />

              </Grid>
            </Grid>



          </Grid>
        </Grid>
       
      </Grid >
      <Button className="!bg-[#1c1c1c] relative !text-white !font-gallient-r font-bold !mt-[40rem] !ml-[37rem]  rounded w-64	h-16"
                onClick={() => {
                  navigate('/CollabFictivInspi2');
                }}> Planche d'inspiration</Button>
    </>
  )
}

MatinBrun1page.propTypes = {
  children: PropTypes.element.isRequired,
};