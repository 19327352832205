import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Header from './Header';
import { UserContext } from '../context/UserContext';

import MockupEnfant from '../assets/images/MockupEnfant.png'
import Pagination from './Pagination'

export default function MatinBrun0page() {
  const { blur } = useContext(UserContext);
  return (

    <>
      <Header />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={blur ? "blur-sm " : ""}

        sx={{ minHeight: '100vh', backgroundColor: '#272035' }}
      >
        <Grid container className="!mt-16" justifyContent="center" spacing={1}>

          <Grid container className="ml-48" justifyContent="center">
            <Grid item xs>
              <p className="-ml-16 mb-8 text-white font-gallient-r text-7xl/[2.5rem] tracking-widest text-center ">
                Matin brun
              </p>
            </Grid>

          </Grid>
          <Grid container className="ml-48" justifyContent="center">
            <Grid item xs={6}>
              <span className="text-[0.95rem] ml-64 contents mt-32 text-white w-[37rem] font-gallient-r  tracking-widest text-center ">
                Matin brun est une nouvelle qui parle d’un état qui interdit la possession de chiens ou chats non bruns
                et condamne tous ceux qui auraient eu un animal brun dans le passé ainsi que leurs familles et amis.<br />
                Ces actions peuvent faire référence à un positionnement de dictature et de propagande.
              </span>
            </Grid>
          </Grid>

          <Grid item xs={3} className="!-ml-[29rem] w-[37rem]">

            <Grid
              container
            >

              <Grid item>
                <p className="text-white font-gallient-r text-7xl/[2.5rem] tracking-widest ">
                  Axe 1
                </p>
                <p className="text-[0.95rem] text-white w-[30rem] mt-8 font-gallient-r tracking-widest ">
                  Dans mon premier choix graphique, j’ai décidé de
                  représenter l’aspect du manque de liberté que la
                  nouvelle laisse transparaître.
                </p>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <p className="text-[0.95rem] text-white w-[30rem] mt-8 font-gallient-r tracking-widest ">

                La couverture représente un enfant explorant son
                monde qui l’entoure avec le reflet de son ombre
                qui signifie qu’il est destiné à suivre la dictature mise
                en place dès son jeune âge, sans avoir la possibilité
                d’évoluer à sa façon.
              </p>
            </Grid>

          </Grid>
          <Grid item xs={5} className="w-80 ml-60">

            <img className="ml-16" src={MockupEnfant} alt="Logo" />
          </Grid>

        </Grid>
        <Grid className="-mt-48 -ml-[92rem]">
          <Pagination
           currentpage={2}
           postsPerPage={2}
           totalPosts={4}
            nextPage={'/MatinBrun1page'}
            previouspage={'/MatinBrun1page'}
            theme={'empty'}

          />
        </Grid>

      </Grid>
    </>
  )
}

MatinBrun0page.propTypes = {
  children: PropTypes.element.isRequired,
};