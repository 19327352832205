import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Header from './Header';
import SawPanneau from '../assets/images/SawPanneau.png'
import SawVid from '../assets/images/SawVideo.mp4'
import { UserContext } from '../context/UserContext';

export default function Saw() {
  const { blur } = useContext(UserContext);
  return (



    <>
      <Header color='black'/>


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={blur ? "blur-sm bg-white " : "bg-white" } 
        sx={{ minHeight: '100vh', position: 'fixed' }}
      >
        <Grid container justifyContent="center" spacing={1}>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid className="mt-8 w-[30rem] -ml-[13rem]">
              <Grid item className="w-full">
                <p
                  className="mt-64 mb-8 text-black font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Saw affiche
                </p>
                <p className="text-[0.70rem] ml-64 contents mt-32 text-black font-gallient-r text-xl tracking-widest text-center ">
                  Le but de ce projet est de créer une affiche de film
                  réaliste en effectuant un travail d’ombres et lumières
                  pouvant être affiché.
                </p>
              </Grid>
              <Grid item className="w-full">
                <p className="text-[0.70rem] ml-64 contents mt-32 text-black  font-gallient-r text-xl tracking-widest text-center ">
                  Pour cette affiche, je me suis inspiré des affiches de
                  saw minimalistes déjà réalisé sur fond blanc, avec la
                  marionnettes de jigsaw, circulant avec son tricycle traînant
                  des traces de sang avec les roues possédant le symbole
                  «X», en référence au film Saw X.
                </p>
              </Grid>
            </Grid>
            <Grid className="flex mt-72 -ml-28">
              <Grid container justifyContent="center">
                <Grid item>
                  <img className="mt-[-5rem] w-[50rem] h-[28rem]" src={SawPanneau} alt="Logo" />

                </Grid>

              </Grid>
              <Grid container justifyContent="center" className="-ml-48">
                <Grid item>
                 
                 
                 
                  <video
                  id="video"
                    loop
                    muted
                    controls
                    onClick={e => e.target.play()}       
                    className="cursor-pointer ml-12 w-[13rem] h-[19rem]"
                   

                  >
                    <source
                      src={SawVid}
                      type="video/mp4"
                    />
                  </video>


    

                </Grid>
              </Grid>
            </Grid>

            <Grid className="w-80 -mt-[20rem] ml-[75rem]">
              <Grid item>
                <span
                  className="ml-64 contents mt-32 text-black font-gallient-r text-xl tracking-widest text-center "
                >
                  Construction affiche<br /><br />  Voici un gif intéressant, qui permets de visualiser
                  l’évolution et la composition de l’affiche dans sa
                  globalité.
                </span>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </>

  )
}

Saw.propTypes = {
  children: PropTypes.element.isRequired,
};