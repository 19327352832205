import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { UserContext } from '../context/UserContext';
import Header from './Header';

import KinderChocolate from '../assets/images/kinderChoco.jpg'
import MockupKinder from '../assets/images/Mockup-kinder-surprise.gif'
import Pagination from './Pagination'

export default function KinderAdvertising() {
  const { blur } = useContext(UserContext);
  return (

    <>
      <Header color={'black'} />


      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={blur ? " blur-sm bg-white" : "bg-white"}

        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>

            <Grid container className="-mt-[10rem] ml-80 w-80 text-center">
              <Grid item xs={8}>
                <p
                  className="w-[59rem] mt-64 text-black font-gallient-r text-7xl/[2.5rem] tracking-widest"
                >
                  Détournement publicitaire
                </p>
              </Grid>


              <Grid>
                <span
                  className="ml-4 text-left w-[63rem] table mt-8 text-black font-gallient-r text-l tracking-widest"
                >
                  Le but du projet était de détourner dans le bon sens ou dans le mauvais la publicité d’une enseigne.<br />
                  Dans mon cas, j’ai décidé de dénoncer des polémiques de grandes marques afin d’accentuer des problèmes important
                  à savoir selon moi.
                </span>

              </Grid>


              <Grid className="-ml-[82rem] mt-16">
                <p className="block w-80 text-left font-gallient-r tracking-widest text-7xl/[2.5rem] text-left "
                >
                  Axe 1
                </p>
                <span className="text-[1rem] mt-4 block w-80 text-left font-gallient-r text-xl tracking-widest">
                  Dans cet axe, le but est de dénoncer certaines
                  polémiques de la marque.<br /><br />
                  Dans le premier cas, la polémique est les bactéries
                  introduites dans les kinder surprise celles des
                  salmonelloses.<br />
                  Mon affiche représente un scanner dévoilant l’intérieur
                  du chocolat avec une phrase d’accroche qui accentue
                  l’effet de surprise.
                </span>


              </Grid>

              <Grid className="mt-40">

                <img className="-ml-24 object-cover h-[20rem] w-[41rem]" src={MockupKinder} alt="Logo" />
              </Grid>

              <Grid className="mt-40 -ml-44">

                <img className="w-96" src={KinderChocolate} alt="Logo" />

              </Grid>

            </Grid>

            <Grid className="ml-[70rem] -mt-[22rem]">

              <span className="text-[1rem] mt-4 block w-80 text-left font-gallient-r text-xl tracking-widest"
              >
                Dans cette création, je souligne le
                problème que Kinder rencontre en
                représentant le côté blanc dans leur
                communication visuelle.<br />
                Leur publicité expose le blanc comme
                un « idéal » (dents blanches, habilles blanc,
                famille blanche), alors que cela reste du
                chocolat donc pas bon pour les dents.

              </span>
            </Grid>

          </Grid>

        </Grid>
        <Grid className="-mt-36 -ml-[92rem]">
          <Pagination
            currentpage={2}
            postsPerPage={2}
            totalPosts={4}
            nextPage={'/SubliminalAdvertising'}
            previouspage={'/SubliminalAdvertising'}
          />
        </Grid>
      </Grid>
    </>
  )
}

KinderAdvertising.propTypes = {
  children: PropTypes.element.isRequired,
};