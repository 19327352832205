import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import animfirstpage from '../assets/images/Animation-Dusky Correction.mp4'
import animDownfirstpage from '../assets/images/Animation-Dusky Reverse.mp4'
import duskyLogo from '../assets/images/Dusky logo orange.png'
export default function DuskyScene() {
  const [blur, setBlur ] = useState(true);
  const [test, setTest ] = useState(true);

  const navigate = useNavigate();

  return (
    <>{ blur ?  <>
    <div className="relative flex items-center justify-center h-screen mb-12 overflow-hidden">
      <div className="-mt-[45rem]">
      <Header />


      </div>
      <video key={test} autoPlay no repeat muted className="scale-y-90">
      { test ? <source src={animfirstpage} type="video/mp4" />
:
        <source src={animDownfirstpage} type="video/mp4" />}
      </video>
      <div
        className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed background-color: rgba(0, 0, 0, 0.6)"
      >
        <div className="flex h-full items-center justify-center">
          <div className="text-white">

            {/* Dusky */}

            <IconButton aria-label="delete" className=" animate-ping-slow -top-24 size-8 right-[40.5rem] white z-20">
              <ModeStandbyIcon style={{ color: 'white' }} 
              onClick={() => {
                setTest(!test);
              }}/>
            </IconButton>
            

            {/* Affiche detourn */}
            <IconButton aria-label="delete" className=" animate-ping-slow top-80 size-8 -left-[2.5rem] white z-20">
              <ModeStandbyIcon style={{ color: 'white' }} 
              onClick={() => {
                setBlur(false);
              }}/>
            </IconButton>

            {/* Saw */}
            <IconButton aria-label="delete" className=" animate-ping-slow -top-36 size-8 right-28 white z-20">
              <ModeStandbyIcon style={{ color: 'white' }} 
              onClick={() => {
                navigate('/Dusky0Page');
              }}/>
            </IconButton>

         

          </div>
        </div>
      </div>
    </div>

</>
:

<div className="relative flex items-center justify-center h-screen mb-12 overflow-hidden">
      <div className="-mt-[45rem]">
      <Header />


      </div>
      <video autoPlay no repeat muted className="scale-y-90">
        <source src={animDownfirstpage} type="video/mp4" />
      </video>
      <div
        className="backdrop-blur-sm absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed background-color: rgba(0, 0, 0, 0.6)"
      >
        <div className="ml-[42rem]  flex h-full items-center justify-center">
          <div className="text-white">

          <Grid className="mt-[9rem] blur-none w-[25rem] -ml-[34rem]">
                <img className="mt-[-5rem] w-[21rem] h-[5rem] mb-4" src={duskyLogo} alt="Logo" />

                <span
                 className="text-left ml-64 contents mt-36 text-white font-gallient-r text-[0.90rem] tracking-widest"
                >
                  « Dusky procède à une vengeance auprès de son histoire, totalement
                  pacifique car cette dernière est retranscrite dans l’art de la mode. »<br /><br />
                  Dusky est une maison qui rend hommage à l’histoire des noirs et toute les
                  personnalités qui se sont battus passivement ou non pour la liberté et l’égalité
                  de cette cause.<br /><br />
                  Le nom « Dusky » signifie obscurité en anglais et fait référence aux actes
                  infligés par certains partis tel que la « Black Panther Party », qui procédait
                  à une sorte de révolution sanglante afin de se faire entendre, mais ce nom
                  signifie également la couleur de peau noir pour que chaque personnes soit
                  fier de son ethnie et de son histoire.
                </span>

                <IconButton aria-label="delete" className=" animate-ping-slow top-12 size-8 !ml-[7.5rem] white z-20">
              <CancelOutlinedIcon style={{ color: 'white' }} 
              onClick={() => {
                setBlur(true);
              }}/>
            </IconButton>
              </Grid>

             

          </div>
        </div>
      </div>
    </div>

     } </>
  )
}

DuskyScene.propTypes = {
  children: PropTypes.element.isRequired,
};