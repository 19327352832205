
import { Grid } from '@mui/material';
import Header from './Header';
import { UserContext } from '../context/UserContext';
import React, { useContext } from 'react';
import Pagination from './Pagination'
import Hogo1 from '../assets/images/Détails/Hogo screen 1.png'
import Hogo2 from '../assets/images/Détails/Hogo screen 2.png'
import Hogo3 from '../assets/images/Détails/Hogo screen 3.png'
import Hogo4 from '../assets/images/Détails/Hogo screen 4.jpg'
import Hogo5 from '../assets/images/Détails/Hogo screen 5.jpg'
import Hogo6 from '../assets/images/Détails/Hogo screen 6.jpg'

export default function HogoDetailsPage() {
  const { blur } = useContext(UserContext);

  return (
    <>
      <Header />


      <Grid

        className={blur ? "blur-sm w-[108%]" : "w-[108%]"}

        sx={{ minHeight: '102vh', backgroundColor: 'black' }}
      >
        <Grid container spacing={1}>



          <Grid className="w-80 ml-8 mt-64">
            <p className=" w-80 mb-4 text-white font-gallient-r text-7xl/[2.5rem] tracking-widest text-left ">
              Détails
            </p>
            <span className="text-[0.70rem] mt-4 contents mt-32 text-white w-80 font-gallient-r text-l tracking-widest text-left ">
              Quelques Détails ont été produits en amont afin de
              définir l’identité visuelle de la marque et mettre en avant
              une direction artistique représentant l’univers japonais et
              sécurisé de la marque.
            </span>
          </Grid>


        </Grid>

        <Grid className="ml-[16rem] -mt-[15rem]">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >

            <Grid item xs={4} className="!basis-[25.285714%]">

              <img className="w-80" src={Hogo1} alt="Logo" />

            </Grid>
            <Grid item xs={4} className="!basis-[25.285714%]">
              <img className="w-80" src={Hogo2} alt="Logo" />
            </Grid>

            <Grid item xs={4} className="!basis-[25.285714%]">
              <img className="w-80" src={Hogo3} alt="Logo" />
            </Grid>

          </Grid>


          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >


            <Grid item xs={4} className="!basis-[25.285714%]">
              <img className="w-[40rem]" src={Hogo4} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[25.285714%]">
              <img className="w-80" src={Hogo5} alt="Logo" />
            </Grid>
            <Grid item xs={4} className="!basis-[25.285714%]">
              <img className="w-80" src={Hogo6} alt="Logo" />
            </Grid>

          </Grid>

        </Grid>

        <Grid className="-mt-16">
          <Pagination
            currentpage={2}
            postsPerPage={3}
            totalPosts={9}
            nextPage={'/HogoCroquisPage'}
            previouspage={'/Hogo'}
            theme={'empty'}
          />
        </Grid>
      </Grid>


    </>
  )

}
