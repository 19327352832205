import React, { useContext } from 'react';

import { UserContext } from '../context/UserContext';
import { Button } from '@mui/material';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import Cv from './Cv Sean Vilocy 2024.pdf';

import { Link } from 'react-router-dom';
import ShortTextRoundedIcon from '@mui/icons-material/ShortTextRounded';

const Header = ({ color }) => {
  const { blur, setBlur } = useContext(UserContext);
  const [anchor, setAnchor] = React.useState(null);

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popup' : undefined;

  return (
    <>
      <div className="cursor-pointer z-10 absolute">
        <ShortTextRoundedIcon 
              fontSize="large"
        className={color === "black" ? "text-black ml-4" : "text-white ml-4"}
                  onClick={() => {
            setBlur(!blur);
          }}></ShortTextRoundedIcon>

        <p className={color === "black" ? "-mt-4  text-black font-gallient-r text-xl tracking-widest text-center " : "-mt-4  text-white font-gallient-r text-xl tracking-widest text-center "}>
         <a href={"/"}>
         WORLD OF SEAN
          </a> 
        </p>

        <p                   onClick={handleClick}
 className={color === "black" ? "-mt-8 ml-[88rem] text-black font-gallient-r text-xl tracking-widest text-center " : "-mt-8 ml-[88rem] text-white font-gallient-r text-xl tracking-widest text-center "}>
          CONTACT
        </p>
       
        <BasePopup
        id={id}
        open={open}
        anchor={anchor}
        disablePortal
        className="z-[3]"
      >
        <iframe className="z-[3] w-[90rem] h-[40rem] relative " src={Cv}
          frameborder="0"/>
      </BasePopup>

      </div>
      <div>
        {blur &&
          <ul onClick={() => { setBlur(!blur);}} className={color === "black" ? "text-black z-10 mt-8 absolute leading-[4rem] blur-none cursor-pointer hover:cursor-pointer block w-[40rem] text-left font-gallient-r text-5xl tracking-widest	" : "text-white z-10 mt-8 absolute leading-[4rem] blur-none cursor-pointer hover:cursor-pointer block w-[40rem] text-left font-gallient-r text-5xl tracking-widest	"}>
              <li><Link to="/">Home</Link> </li>
              <li><Link to="/Hogo">Hogo</Link> </li>
              <li><Link to="/MatinBrun0page">Matin Brun</Link></li>
              <li><Link to="/SawPage">Saw poster</Link></li>
              <li><Link to="/CollabFictivHome">Collaboration fictive</Link></li>
              <li><Link to="/KinderAdvertising">Détournement publicitaires</Link></li>
              <li><Link to="/Dusky0Page">Dusky</Link></li>
              <li><Link to="/DuskyScene">Dusky House</Link></li>
              <li><Link to="/Divers1">Divers</Link></li>
          </ul>

        }
      </div>
    </>
  )
}
export default Header;
