import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const Pagination = ({ postsPerPage, totalPosts, previouspage, nextPage, currentpage, theme, blackChevron }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  console.log(pageNumbers)

  return (


    <div className="mt-40">
      <nav>
        <ul className="list-style-none flex">
          {pageNumbers.map(number => (
            <li className="page-item" key={number}>
              {theme === "empty"

                ?
                <div
                  className={
                    number === currentpage ? "relative block rounded-full bg-neutral-700 pl-4 py-2 ml-1 transition duration-300 hover:bg-neutral-700 focus:text-primary-700 focus:outline-none active:bg-neutral-700 active:text-primary-700 bg-slate-400 focus:bg-neutral-100 active:bg-neutral-100 active:text-primary-500"
                      : "relative block rounded-full bg-neutral-700 pl-4 py-2 ml-1 transition duration-300 hover:bg-neutral-700 focus:bg-neutral-700 focus:text-primary-700 focus:outline-none active:bg-neutral-700 active:text-primary-700 dark:bg-neutral-100 dark:focus:bg-neutral-100 dark:active:bg-neutral-100 dark:active:text-primary-500"
                  } />
                :

                <div
                  className={
                    number === currentpage ? "relative block rounded-full bg-transparent pl-4 py-2 ml-1 transition duration-300 hover:bg-neutral-100 focus:text-primary-700 focus:outline-none active:bg-neutral-100 active:text-primary-700 bg-slate-400 focus:bg-neutral-700 active:bg-neutral-700 active:text-primary-500"
                      : "relative block rounded-full bg-transparent pl-4 py-2 ml-1 transition duration-300 hover:bg-neutral-100 focus:bg-neutral-100 focus:text-primary-700 focus:outline-none active:bg-neutral-100 active:text-primary-700 dark:bg-neutral-700 dark:focus:bg-neutral-700 dark:active:bg-neutral-700 dark:active:text-primary-500"
                  } />

              }


            </li>
          ))}
          <div className="flex fixed ml-[45rem]">
            <li className="border-solid border-slate-100">

              <a
                className=""
                href={previouspage}
              > {blackChevron === "black"
                ?
                <div className="relative block rounded-full bg-black px-3 py-1.5 text-sm text-surface transition duration-300 hover:bg-neutral-100 focus:bg-neutral-100 focus:text-primary-700 focus:outline-none active:bg-neutral-100 active:text-primary-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 dark:focus:text-primary-500 dark:active:bg-neutral-700 dark:active:text-primary-500 border rounded-full"><ChevronLeftIcon>Previous
                </ChevronLeftIcon></div>
                :
                <div className="relative block rounded-full bg-transparent px-3 py-1.5 text-sm text-surface transition duration-300 hover:bg-neutral-100 focus:bg-neutral-100 focus:text-primary-700 focus:outline-none active:bg-neutral-100 active:text-primary-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 dark:focus:text-primary-500 dark:active:bg-neutral-700 dark:active:text-primary-500 border rounded-full"><ChevronLeftIcon>Previous
                </ChevronLeftIcon></div>
                }</a>

            </li>
            <li>
              <a
                href={nextPage}
              >
                {blackChevron === "black"
                  ?
                  <div className="relative block rounded-full bg-black px-3 py-1.5 text-sm text-surface transition duration-300 hover:bg-neutral-100 focus:bg-neutral-100 focus:text-primary-700 focus:outline-none active:bg-neutral-100 active:text-primary-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 dark:focus:text-primary-500 dark:active:bg-neutral-700 dark:active:text-primary-500 border rounded-full"><ChevronRightIcon>Previous
                  </ChevronRightIcon></div>
                  :
                  <div className="relative block rounded-full bg-transparent px-3 py-1.5 text-sm text-surface transition duration-300 hover:bg-neutral-100 focus:bg-neutral-100 focus:text-primary-700 focus:outline-none active:bg-neutral-100 active:text-primary-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 dark:focus:text-primary-500 dark:active:bg-neutral-700 dark:active:text-primary-500 border rounded-full"><ChevronRightIcon>Previous
                  </ChevronRightIcon></div>
                }
              </a
              >
            </li>

          </div>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
