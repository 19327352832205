import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Header from './Header';
import { UserContext } from '../context/UserContext';
import dusky from '../assets/images/Vue arrière dusky.png'
import duskyA from '../assets/images/Vue avant dusky.png'
import duskyy2 from '../assets/images/AnimationDuskyReveal.mp4'
import Pagination from './Pagination'
import DuskyPoster from '../assets/images/miniature/Miniature Dusky boite.jpg'


export default function Dusky0Page() {
  const { blur } = useContext(UserContext);


  return (

    <>
      <Header />

      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={blur ? "blur-sm w-full fixed " : "w-full fixed"}
        sx={{ minHeight: '102vh', backgroundColor: '#271e1e' }}
      >
        <Grid className="-ml-[28rem]">
          <Grid container justifyContent="center" spacing={1}>

            <Grid container className="mt-8 ml-[27rem] w-80 text-center">



              <Grid className="mt-[5rem]">
                <p className="block w-80 text-left text-amber-700	font-gallient-r tracking-widest text-7xl/[2.5rem] text-left "
                >
                  Dusky
                </p>
                <span className="mt-8 block w-[28rem] text-left text-amber-700	 font-gallient-r tracking-widest">
                  La veste s’inspire de l’habille des samouraïs japonais
                  plus particulièremet de Yasuke, qui est le premier
                  samouraï noir. Cela représente l’aspect militant et
                  guerrier des activistes noirs.<br /><br />
                  Cette veste s’inspire également du cuir des Black
                  Panther Party mais plus moderne en se questionnant
                  sur des problèmes écologiques, comme le cuir végétal.


                </span>

              </Grid>

              <Grid className="mt-20 relative -left-[2rem]">

                <img className="w-[42rem]" src={duskyA} alt="Logo" />

              </Grid>
              <Grid className=" relative mt-[-5rem] ">
                <Grid className="mt-20">


                  <video
                    loop
                    muted
                    controls
                    onClick={e => e.target.play()}
                    className="scale-100 	hover:scale-100 ease-in duration-500 cursor-pointer	 w-[20rem] h-[14rem]"
                   
                    poster={DuskyPoster}

                  >
                    <source
                      src={duskyy2}
                      type="video/mp4"
                    />
                  </video>



                </Grid >
                <Grid className="">

                  <img className="w-80 mt-8" src={dusky} alt="Logo" />

                </Grid>
              </Grid>


            </Grid>

          </Grid>

        </Grid>
        <Grid />



        <Grid className="mt-[-8rem] -ml-[92rem]">
          <Pagination
            currentpage={1}
            postsPerPage={2}
            totalPosts={4}
            nextPage={'/Dusky1Page'}
            previouspage={'/Dusky1Page'}
            theme={'empty'}
          />
        </Grid>
      </Grid >

    </>




  )
}


Dusky0Page.propTypes = {
  children: PropTypes.element.isRequired,
};