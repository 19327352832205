import React, { Suspense, useContext, useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

import Header from './Header';
import animfirstpage from '../assets/images/Animation site 0040-0130.mkv'
import { Grid } from '@mui/material';
import cherubin from '../assets/images/Cherubin loading.gif'
import animAigle from '../assets/images/Anim Aigle FINAL.mp4'


export default function Scene1() {
  const { blur, setBlur } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseP = () => {
    setOpen(false);
  };

  const navigate = useNavigate();


  const [isDisplayed, setIsDisplayed] = useState(true);

  const VideoRef = useRef();



  useEffect(() => {
    const timer = setTimeout(() => setIsDisplayed(false), 1700)
    return () => clearTimeout(timer);
  }, [])
  return (
    <>
      {isDisplayed ?
        <>
          <Header />


          <Grid

            className={blur ? "blur-sm scale-y-95" : "scale-y-95"}

            sx={{ minHeight: '102vh', backgroundColor: 'black' }}
          >

            <Grid item xs={4} className="!basis-[12.285714%]">
              <img className="w-[86rem]" src={cherubin} alt="Logo" />
            </Grid>

          </Grid>
        </>
        :

        <>

          {blur &&
            <ul onClick={() => { setBlur(!blur);}} className="text-white z-10 mt-16 absolute leading-[4rem] blur-none cursor-pointer hover:cursor-pointer block w-[40rem] text-left font-gallient-r text-5xl tracking-widest	">
              <li><Link to="/">Home</Link> </li>
              <li><Link to="/Hogo">Hogo</Link> </li>
              <li><Link to="/MatinBrun0page">Matin Brun</Link></li>
              <li><Link to="/SawPage">Saw poster</Link></li>
              <li><Link to="/CollabFictivHome">Collaboration fictive</Link></li>
              <li><Link to="/KinderAdvertising">Détournement publicitaires</Link></li>
              <li><Link to="/Dusky0Page">Dusky</Link></li>
              <li><Link to="/DuskyScene">Dusky House</Link></li>
              <li><Link to="/Divers1">Divers</Link></li>
            </ul>}

          <div className={blur ? "blur-sm relative flex items-center justify-center h-screen mb-12 overflow-hidden" : "relative flex items-center justify-center h-screen mb-12 overflow-hidden"
          }>
            <div className="-mt-[44.75rem]" >
              <Header />
            </div>
            <video autoPlay loop muted className="bg-cover">
              <source src={animfirstpage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div
              className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed background-color: rgba(0, 0, 0, 0.6)"
            >
              <div className="flex h-full items-center justify-center">



                <div className="text-white">
                  {/* Vêtement hogo */}
                  <Suspense fallback={<div>Wait...</div>}>

                    <IconButton aria-label="delete" className=" animate-ping-slow top-24 size-8 right-40 white z-20">

                      <ModeStandbyIcon style={{ color: 'white' }}
                        onClick={() => {
                          navigate('/Hogo');
                        }} />

                    </IconButton>
                  </Suspense>
                  {/* Dusky */}
                  <IconButton aria-label="delete" className=" animate-ping-slow -top-4 size-8 right-[27rem] white z-20">

                    <ModeStandbyIcon style={{ color: 'white' }}
                      onClick={() => {
                        navigate('/DuskyScene');
                      }} />


                  </IconButton>
                  {/* Affiche detourn */}
                  <IconButton aria-label="delete" className=" animate-ping-slow top-[21rem] size-8 left-[11.5rem] white z-20">

                    <ModeStandbyIcon style={{ color: 'white' }}
                      onClick={() => {
                        navigate('/MatinBrun0page');
                      }} />


                  </IconButton>
                  {/* Burbe */}
                  <IconButton aria-label="delete" className=" animate-ping-slow -top-64 size-8 right-56 white z-20">

                    <ModeStandbyIcon style={{ color: 'white' }}
                      onClick={() => {
                        navigate('/SubliminalAdvertising');
                      }} />

                  </IconButton>

                  {/* Saw */}
                  <IconButton aria-label="delete" className=" animate-ping-slow -top-64 size-8 right-32 white z-20">

                    <ModeStandbyIcon style={{ color: 'white' }}
                      onClick={() => {
                        navigate('/SawPage');
                      }} />


                  </IconButton>
                  {/* Aigle */}
                  <IconButton aria-label="delete" className=" animate-ping-slow -top-24 size-8 -right-48 white z-20">

                    <ModeStandbyIcon style={{ color: 'white' }}
                      onClick={handleOpen} />

                  </IconButton>

                  {/* Table */}
                  <IconButton aria-label="delete" className=" animate-ping-slow top-4 size-8 -right-44 white z-20">

                  <ModeStandbyIcon style={{ color: 'white' }}
                      onClick={() => {
                        navigate('/Divers1');
                      }} />


                  </IconButton>

                  <Modal
                    open={open}
                    onClose={handleCloseP}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                  >
                    <video controls loop muted ref={VideoRef} className="w-80 ml-[37rem] mt-[13rem] bg-cover">
                      <source src={animAigle} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Modal>


                </div>
              </div>
            </div>
          </div></>
      }

    </>
  )
}

Scene1.propTypes = {
  children: PropTypes.element.isRequired,
};